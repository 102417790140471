import {
  Old_Shipping_Egypt_Id,
  WUILT_SHIPMENT_EGYPT_ID,
} from "@/components/UtilityComponents/ShippingDestinationSelect";
import { useStore } from "@/lib/storeData/StoreContext";

const useCountryValidation = (countryId: string): boolean => {
  const { isWuiltShipmentActive } = useStore();
  if (
    (countryId === Old_Shipping_Egypt_Id && isWuiltShipmentActive) ||
    (!isWuiltShipmentActive && countryId === WUILT_SHIPMENT_EGYPT_ID)
  ) {
    return false;
  }

  return true;
};

export default useCountryValidation;
